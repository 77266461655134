var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"10px"}},[_c('CTabs',{attrs:{"variant":"tabs","active-tab":0}},[_c('CTab',{attrs:{"title":_vm.$t('label.menu_diary.TERMINAL_DETAILS')}},[_c('CCard',{attrs:{"bodyWrapper":""}},[_c('CCollapse',{attrs:{"show":!_vm.collapseTerminal}},[_c('CRow',[_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"col":"12","xl":"12"}},[_c('CButton',{attrs:{"color":"add"},on:{"click":_vm.nuevoToquen}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nuevo'))+" ")],1)],1),_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","column-filter":"","loading":_vm.apiStateLoading,"table-filter":_vm.tableTextHelpers.tableFilterText,"items-per-page-select":_vm.tableTextHelpers.itemsPerPageText,"items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableTextHelpers.noItemsViewText,"items-per-page":10,"pagination":""},scopedSlots:_vm._u([{key:"acciones",fn:function({item, index}){return [_c('td',[_c('CButtonGroup',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                    content: _vm.$t('label.edit'),
                                                    placement: 'top-end'
                                                }),expression:"{\n                                                    content: $t('label.edit'),\n                                                    placement: 'top-end'\n                                                }"}],staticClass:"mr-1",attrs:{"color":"primary","square":"","size":"sm"},on:{"click":function($event){return _vm.updateItem(item, index)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                                                    content: _vm.$t('label.Romeve'),
                                                    placement: 'top-end'
                                                }),expression:"{\n                                                    content: $t('label.Romeve'),\n                                                    placement: 'top-end'\n                                                }"}],staticClass:"mr-1",attrs:{"color":"danger","square":"","size":"sm"}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)],1)]}}])})],1)],1)],1),_c('CCollapse',{attrs:{"show":_vm.collapseTerminal}},[_c('appRegisterTerminal',{attrs:{"visitaTerminalId":_vm.visitaTerminalId,"itemSelected":_vm.itemSelected,"apiStateLoading":_vm.apiStateLoading,"token":_vm.NextCalled},on:{"submited":_vm.handleSubmit,"asignarTerminal":_vm.asignarTerminal}})],1)],1)],1),(_vm.showTransactionHistory)?_c('CTab',{attrs:{"title":_vm.$t('label.recordInfo')}},[_c('CCard',{attrs:{"bodyWrapper":""}},[_c('CRow',[_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"col":"12","xl":"12"}},[_c('CButton',{staticClass:"mr-1",attrs:{"color":"excel"},on:{"click":function($event){return _vm.onBtnExport(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XSLX ")],1)],1),_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","column-filter":"","loading":_vm.apiStateLoading,"table-filter":_vm.tableTextHelpers.tableFilterText,"items-per-page-select":_vm.tableTextHelpers.itemsPerPageText,"items":_vm.formatedItemsSchedule,"fields":_vm.fieldsRecord,"noItemsView":_vm.tableTextHelpers.noItemsViewText,"items-per-page":10,"pagination":""},scopedSlots:_vm._u([{key:"Label",fn:function({item}){return [_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.titleItemsActivity(item))+" ")])]}}],null,false,110840156)})],1)],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }